<template>
  <div class="footer">
    <img
      class="footer-image"
      src="@/assets/images/logo.svg"
      alt="">
    <div class="footer-contacts">
      <a
        class="footer-contacts-company"
        href="tel:+79827500000">+79827500000</a>
      <p class="footer-contacts-company">ООО "ОРКА ДЕВ"</p>
    </div>
  </div>
</template>

<script lang="ts">
</script>

<style lang="scss" scoped>
.footer {
  height: get-vw(250px);
  padding: 0 get-vw(40px);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: #161819;
  &-image {
    width: get-vw(200px);
  }
  &-contacts {
    display: flex;
    flex-direction: column;
    &-company {
      color: #FFFFFF;
      font-family: "DM Mono", "Fira Code", monospace;
      font-weight: 500;
      letter-spacing: -0.01em;
      font-size: get-vw(17px);
    }
  }
}
</style>
